import logo from './logo.svg';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="LandingPage">
      <header className="LandingPage-header">
        <img src={logo} className="LandingPage-logo" alt="logo" />
        <p className="loading-text">
          loading...
        </p>
      </header>
    </div>
  );
}

export default LandingPage;
